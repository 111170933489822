<template>
  <div>
    <div v-if="tutoratData" style="margin-bottom: 40px">
      <h1>
        Evaluation de <i>{{ tutoratData.pupille.nom_complet }}</i>
      </h1>
      <h2>
        par <i>{{ tutoratData.tuteur.nom_complet }}</i>
      </h2>
    </div>
    <h4 v-else>Ajouter une évaluation</h4>
    <div class="app-form">
      <el-form
        ref="formEvaluation"
        :model="form"
        label-width="100px"
        label-position="right"
        :rules="rules"
        :validate-on-rule-change="false"
      >
        <el-row>
          <el-col :span="12">
            <!-- Date -->
            <el-form-item label="Date" prop="eval_date">
              <el-date-picker
                v-model="form.eval_date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="date"
                placeholder="Format: aaaa-mm-jj"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
            <!-- Remarques -->
            <el-form-item class="texte-long" label="Remarques" prop="remarques">
              <el-input
                v-model="form.remarques"
                type="textarea"
                autosize
              ></el-input>
            </el-form-item>
            <!-- Evaluation doc pdf -->
            <el-form-item label="Fiche d'évaluation" prop="evalfile">
              <div class="eval-uploader">
                <!-- Eval upload -->
                <el-tooltip class="item" effect="light" placement="bottom">
                  <div slot="content">
                    Télécharger une fiche :
                    <ul>
                      <li>format : PDF</li>
                      <li>taille : max 2 Mo</li>
                    </ul>
                  </div>
                  <div class="eval-upload-upload" v-show="!evalUrl">
                    <input
                      class="eval-input-file"
                      type="file"
                      ref="evalInput"
                      @change="uploadEval"
                    />
                    <i class="el-icon-upload2"></i>
                  </div>
                </el-tooltip>
                <!-- Eval open -->
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="bottom"
                  content="Ouvrir la fiche"
                >
                  <div class="eval-upload-open" v-show="evalUrl">
                    <a :href="evalUrl" target="_blank"
                      ><i class="el-icon-view"></i
                    ></a>
                  </div>
                </el-tooltip>
                <!-- Eval delete -->
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="bottom"
                  content="Supprimer la fiche"
                >
                  <div
                    class="eval-upload-delete"
                    v-show="evalUrl"
                    @click="deleteEval"
                  >
                    <i class="el-icon-delete"></i>
                  </div>
                </el-tooltip>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var validEvalDate = (rule, value, callback) => {
      if (value) {
        if (this.autresEvalDates.includes(value)) {
          callback(
            new Error(
              "Cette date est déjà attribuée à une autre évaluation du même tutorat"
            )
          );
        } else {
          if (
            this.tutoratData.debut_date &&
            this.tutoratData.debut_date > value
          ) {
            callback(
              new Error(
                "La date ne peux pas être antérieure au début du tutorat !"
              )
            );
          } else if (
            this.tutoratData.fin_date &&
            this.tutoratData.fin_date < value
          ) {
            callback(
              new Error(
                "La date ne peux pas être postérieure à la fin du tutorat !"
              )
            );
          } else {
            callback();
          }
        }
      } else {
        callback(new Error("Ce champ est obligatoire"));
      }
    };
    return {
      EvalFolder: "/rhfiles/evals/",
      rules: {
        eval_date: [
          { required: true, validator: validEvalDate, trigger: "change" },
        ],
      },
    };
  },
  computed: {
    form: {
      // https://vuex.vuejs.org/guide/forms.html#two-way-computed-property
      get() {
        return this.$store.state.eva.evaluation;
      },
      set(form) {
        this.$store.commit("eva/setEvaluation", form);
      },
    },
    idTutorat() {
      // récupère le id du tutorat dans l'url
      return String(this.$route.params.idtutorat);
    },
    idEval() {
      return String(this.$route.params.ideval);
    },
    tutoratData() {
      // récupère les données du tutorat édité
      const tutorat = this.$store.state.tut.tuteur;
      // si la page est directement chargée on recupère les données en filtrant la liste des tutorats
      // autrement on les récupère du tutorat ouvert
      return Object.keys(tutorat).length == 0
        ? this.$store.state.tut.tuteurs.filter((t) => t.id == this.idTutorat)[0]
        : tutorat;
    },
    autresEvalDates() {
      // array avec les dates des autres évaluations pour ce tutorat
      const evals = this.$store.state.eva.evaluations
        .filter((e) => e.id_medtuteur == this.idTutorat && e.id != this.idEval)
        .map((e) => e.eval_date);
      return evals;
    },
    evalUrl() {
      const fileNom = this.$store.state.eva.evalFileNom; //? this.$store.state.eva.evalFileNom : this.form.evalfile ;
      return fileNom
        ? process.env.VUE_APP_API_URL +
            "/rhfiles/evals/" +
            fileNom +
            "?rnd=" +
            Math.random()
        : null;
      // ajoute '?rnd=' + Math.random() à l'URL pour changer le nom du cv pour q'il soit chargé
    },
  },
  methods: {
    async uploadEval(event) {
      const file = event.target.files[0]; // récupère l'objet 'fichier'
      // Vérifie le fichier importé ( pdf, max 2Mo)
      const isPDF = file.type == "application/pdf";
      const isMaxSize = file.size <= 2097152; //(en octet, file <= 2Mo)
      if (!isPDF) {
        this.$alert("La fiche doit être au format PDF !", {
          confirmButtontext: "OK",
        });
      } else if (!isMaxSize) {
        this.$alert("Le fichier ne doit pas excéder 2 Mo !", {
          confirmButtonText: "Ok",
        });
      } else {
        // upload le fichier
        this.$store.dispatch("eva/uploadEvalFile", {
          file: file,
          id: this.form.id,
        });
        // reset le input file (autrement marche pas si on charge plusieurs fois le même fichier)
        this.$refs.evalInput.value = null;
      }
    },
    deleteEval() {
      this.$confirm("Sûr-e ?!", "Attention !", {
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("eva/deleteEvalFile", this.form.id);
      });
    },
  },
};
</script>
