<template>
  <el-main class="rh-form">
    <form-evaluationcreate :routes="evaRoutes"></form-evaluationcreate>
  </el-main>
</template>
<script>
import FormEvaluationcreate from "@/components/rh/formulaires/FormEvaluationcreate";

export default {
  components: { "form-evaluationcreate": FormEvaluationcreate },
  computed: {
    evaRoutes() {
      return {
        toedit: {
          name: "evaluationedit",
          params: {
            idtutorat: this.$route.params.idtutorat,
            ideval: this.$store.state.eva.evaluationLastCreatedId,
          },
        },
        tolist: {
          name: "tutoratedit",
          params: { idtutorat: this.$route.params.idtutorat },
        },
      };
    },
  },
};
</script>
