<template>
  <div>
    <form-evaluation ref="formEval"> </form-evaluation>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormEvaluation from "@/components/rh/ui/form/FormEvaluation";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-evaluation": FormEvaluation },

  data() {
    return {
      evalFile: {},
      isSaving: false,
    };
  },
  props: {
    routes: Object,
  },
  created() {
    const idTut = this.$route.params.idtutorat;
    this.$store.commit("eva/resetEvaluation", idTut);
  },
  computed: {
    evaluationCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.eva.evaluationCreateStatus;
    },
    evalfileUpLoadStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.eva.evalFileUploadStatus;
    },
    evalfileDeleteStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.eva.evalFileDeleteStatus;
    },
  },
  destroyed() {
    // supprime le fichier temporaire 'public/files/evals/temp_eval_file.pdf' lorsque l'on quitte le formulaire sans enregistrer
    this.$store.dispatch("eva/deleteEvalFile", "");
  },
  methods: {
    onSubmit() {
      this.$refs.formEval.$refs.formEvaluation.validate((valid) => {
        if (valid) {
          let evadata = this.$store.state.eva.evaluation;

          // enlever les espaces avant et après dans les string
          trimStringInObj(evadata);

          // vérifie si une évaluation pour le même tutorat, à la même date existe déjà
          const evasInBD = this.$store.getters["eva/EvasInBD"];
          const doublon =
            evasInBD.filter(
              (m) => m[1] == evadata.id_medtuteur && m[2] == evadata.eval_date
            ).length > 0;
          if (doublon) {
            this.$alert(
              "Une évaluation à la même date existe déjà pour ce tutorat !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("eva/createEvaluation", evadata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des formations continues
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    evaluationCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.evaluationCreateStatus == 2) {
        // message succès
        this.$message({
          message: "L'évaluation a été ajoutée à la base de données",
          type: "success",
        });
        // va au formulaire d'édition de l'évaluation
        this.$router.push(this.routes.toedit);
      } else if (this.evaluationCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.evaluationCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.evaluationCreateStatus == 0) {
        this.isSaving = false;
      }
    },
    evalfileUpLoadStatus() {
      if (this.evalfileUpLoadStatus == 3) {
        this.$message.error({
          message: "Echec de l'enregistrement de la fiche !",
        });
      }
    },
    evalfileDeleteStatus() {
      if (this.evalfileDeleteStatus == 3) {
        this.$message.error({
          message: "Echec de la suppression de la fiche !",
        });
      }
    },
  },
};
</script>
